import "./auth/index";

//Select2 HP
$(document).ready(function() {
  $(".brands-hp").addClass("ready");
  $(".brands-hp").select2({
    minimumInputLength: 3,
    placeholder: "Brands",
    width: "resolve",
    ajax: {
      url: "/api/brands",
      delay: 250,
      dataType: "json",
      processResults: function(data) {
        return {
          results: data,
        };
      },
      cache: true,
    },

    templateResult: function(data) {
      if (data.id == null) {
        return data.text;
      }

      var $option = $("<a class='link'" + "</a>");
      $option.prop("href", "/brand/" + data.slug);
      $option.on("mouseup", function(evt) {
        // Select2 will remove the dropdown on `mouseup`, which will prevent any `click` events from being triggered
        // So we need to block the propagation of the `mouseup` event
        evt.stopPropagation();
      });

      //$option.on('click', function (evt) {
      //	console.log('the link was clicked');
      //});

      $option.text(data.text);

      return $option;
    },
  });

  $(".search.search-brand").addClass("ready");
  $(".search.search-brand").select2({
    minimumInputLength: 3,
    placeholder: "Brands",
    width: "resolve",
    ajax: {
      url: "/api/brands",
      delay: 250,
      dataType: "json",
      processResults: function(data) {
        return {
          results: data,
        };
      },
      cache: true,
    },

    templateResult: function(data) {
      if (data.id == null) {
        return data.text;
      }

      var $option = $("<a class='link'" + "</a>");
      $option.prop("href", "/brand/" + data.slug);
      $option.on("mouseup", function(evt) {
        // Select2 will remove the dropdown on `mouseup`, which will prevent any `click` events from being triggered
        // So we need to block the propagation of the `mouseup` event
        evt.stopPropagation();
      });

      //$option.on('click', function (evt) {
      //	console.log('the link was clicked');
      //});

      $option.text(data.text);

      return $option;
    },
  });
});

//list.js Brands Page
// $(function() {
//   var options = {
//     valueNames: ["brand-item"],
//     page: 6,
//     pagination: true,
//   };

//   var singlebrands = new List("brands-list", options);
// });

//list.js News Page
$(function() {
  var options = {
    valueNames: ["title"],
    page: 10,
    pagination: true,
  };
  var singlenews = new List("news-list", options);
});

//Ajax button call for download

$(".file-dl").click(function(e) {
  //e.preventDefault();

  var self = $(this),
    docId = self.data("doc");

  $.ajax({
    url: "/register-download?doc=" + docId,
    success: function(data) {
      //console.log(docId)
    },
  });
});

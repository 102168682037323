import Swiper from "swiper";

// Css
import "swiper/css/swiper.css";

// Slider
const s = new Swiper(".auth-slider", {
  slidesPerView: 1,
  centeredSlides: true,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
});

import Swiper from "swiper";

// Css
import "swiper/css/swiper.css";

// Slider
const s = new Swiper(".product-slider", {
  slidesPerView: 1,
  centeredSlides: true,
});

// slider mini
$(".product-slider__mini").on("click", (e) => {
  const activeClass = "product-slider__mini-active";
  const { target } = e;
  const el = target.closest(".product-slider__mini");

  $(".product-slider__mini").each((_, el) => $(el).removeClass(activeClass));

  $(el).addClass(activeClass);
  s.slideTo(el.dataset.index);
});

// Show quotation form
jQuery(".o-quotation.btn").on("click", () => {
  jQuery(".o-quotation-note").slideToggle();
});

/**
 * @param {Boolean} load
 */
const loading = (load) => {
  const fn = load ? "add" : "remove";
  jQuery(".o-quotation-form-btn")[`${fn}Class`]("running");
};

// jQuery(".o-quotation-form response").html("");
// jQuery(".o-quotation-form response").removeClass(["error", "valid"]);

const handleErrors = (errors, show = true) => {
  jQuery(".o-quotation-form .response")
    .html(Object.values(errors).join(", "))
    .addClass("error");
};

const handleValid = () => {
  jQuery(".o-quotation-form .response")
    .html("Thanks, your request has been submitted")
    .addClass("valid");
};

// Quotation form handler
jQuery(".o-quotation-form-btn").on("click", async (e) => {
  e.preventDefault();

  if (window.loading) return;
  window.loading = true;

  loading(true);

  const form = document.querySelector(".o-quotation-form");

  const {
    action: url,
    _token: { value: token },
    product,
    note,
  } = form;

  const resp = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": token,
    },
    body: JSON.stringify({
      product: product.value,
      note: note.value,
    }),
  });

  const json = await resp.json();

  if (json.errors) {
    loading(false);
    handleErrors(json.errors);
    window.loading = false;

    return;
  }

  if (!json.failed) {
    handleValid();
    loading(false);

    setTimeout(() => {
      $(".o-quotation.btn").attr("disabled", true);
      $(".o-quotation.btn").off("click");
      jQuery(".o-quotation-note").slideUp();
    }, 3000);
  }

  window.loading = false;
});
